<template>
  <div class="userInfoDetail">
    <pageHead class="userInfoDetail_head" title="个人中心">
      <!--      <img class="userInfoDetail_iconSearch" slot="right" src="/images/userinfoDetailView/icon-search.png"-->
      <!--           @click="handleSearch"/>-->
    </pageHead>
    <div class="userInfoDetail_infoCard">
      <userInfoCard :user-info="userInfo" v-bind="userInfo" />
      <p class="tag" @click="handleSendPrivateMsg">私信</p>
    </div>
    <div class="userInfoDetail_filter">
      <p class="articleNum">
        <span>帖子数量(</span>
        <span>{{ totalArticleNum }}</span>
        <span>)</span>
      </p>
      <selectView
        v-model="sortKey"
        :options="selectOptions"
        @input="changeSort"
      />
    </div>
    <div v-infinite-scroll="loadMore" class="userInfoDetail_content">
      <topicItem
        class="topicItem"
        v-for="(item, index) in articleList"
        :key="item.id"
        :data="item"
        @share="handleShare"
        @like="like($event, index)"
        @favorite="favorite($event, index)"
        @originalClick="handleTopicClick"
        @click="handleTopicClick"
        @comment="handleComment"
        @handleNewActive="handleNewActive"
        :showLine="false"
      />
      <no-more v-if="!this.hasNextPage" text="没有更多数据" class="no-more" />
    </div>
    <commentToolDialog
      :show-dialog.sync="showDialog"
      :isAnonymousComment.sync="isAnonymousComment"
      v-model="commentContent"
      hide-relation
      hide-img
      @publish="handlePublishComment"
    />
  </div>
</template>

<script>
import pageHead from '@/components/pageHead'
import userInfoCard from './components/userInfoCard'
import selectView from '@/components/selectView'
import topicItem from '@/components/topicItem'
import NoMore from '@/components/uitls/NoMore'
import commentToolDialog from '@/components/commentToolDialog'

import { /*articleCountByUserId,*/ getUserByPage} from '@/api/article'
import {getUserById} from '@/api/suser'
import {addFavorites, removeFavorites} from '@/api/favorites'
import {addThumbsup, removeThumbsup} from '@/api/thumbsup'
import {addComment} from '@/api/comment'
import {ADMIN_NAME} from '@/config/config'
import {getItem, setItem} from "@/utils/storage";
// import {Encrypt} from "@/utils/utils"

const ENTITY_TYPE_ARTICLE = 2

export default {
  // props: {
  //   userId: [String, Number]
  // },
  components: {
    pageHead,
    userInfoCard,
    selectView,
    topicItem,
    NoMore,
    commentToolDialog
  },
  data () {
    return {
      totalArticleNum: 0,
      userInfo: {},
      sortKey: 'time',
      articleList: [],
      selectOptions: [
        {label: '按时间排序', value: 'time'},
        {label: '按热度排序', value: 'like'}
      ],
      pageNum: 1,
      hasNextPage: true,
      showDialog: false,
      isAnonymousComment: false,
      commentContent: '',
      userId: ''
    }
  },
  mounted () {
    // this.getUserArticleCount()
    // this.getArticleList(this.pageNum)

    let userId = this.$route.params.userId
    if (this.$route.query.userId) {
      userId = this.$route.query.userId
    }
    if (userId) {
      this.$store.commit('user/SET_USERDETAILID', this.$route.params.userId)
      this.userId = userId
    } else {
      this.userId = this.$store.state.user.userDetailId
    }
    let sortKey = getItem('sortKey') || ''
    this.sortKey = sortKey || 'time'
    this.getUserInfo()
  },
  methods: {
    handleSearch () {
      console.log('handleSearch')
    },
    changeSort () {
      this.articleList = []
      this.pageNum = 1
      this.hasNextPage = true
      this.getArticleList(this.pageNum)
    },
    /**
     * 获取用户文章统计
     */
    // async getUserArticleCount() {
    //   this.totalArticleNum = await articleCountByUserId(this.userId)
    // },
    /**
     * 获取用户信息
     * @returns {Promise<void>}
     */
    async getUserInfo () {
      // const {
      //   username,
      //   avatar: userIcon,
      //   deptStr,
      //   superAdmin
      // } = await getUserById(this.userId)
      let userInfo = await getUserById(this.userId)
      userInfo['userIcon'] = userInfo.avatar
      userInfo['department'] = userInfo.deptStr
      userInfo['admin'] = userInfo.superAdmin
      this.userInfo = userInfo
      // this.userInfo = {
      //   username,
      //   userIcon,
      //   department: deptStr,
      //   admin: superAdmin
      // }
    },
    /**
     * 获取文章列表
     * @returns {Promise<void>}
     */
    async getArticleList (pageNum) {
      const {userId, sortKey} = this
      let sortType = ''
      if (sortKey === 'like') {
        sortType = 2
      } else {
        sortType = 1
      }
      const {
        records: articleList,
        current,
        pages,
        total
      } = await getUserByPage({
        pageNum,
        pageSize: 10,
        userId: userId,
        sortType
      })
      this.hasNextPage = current < pages
      this.pageNum = this.pageNum + 1
      this.articleList = this.articleList.concat(articleList)
      this.totalArticleNum = total
    },
    /**
     * 分享事件
     */
    handleShare ({id: quoteId} = {}) {
      this.$router.push({name: 'createTopic', query: {quoteId}})
    },
    /**
     * 发送私信
     */
    handleSendPrivateMsg () {
      const {
        userId,
        userInfo: {username, admin}
      } = this
      let userName = admin === 1 || admin === 2 ? ADMIN_NAME : username
      this.$router.push({
        name: 'messageChatView',
        query: {userId, userName}
      })
    },
    handleTopicClick ({articleId} = {}) {
      this.$router.push({name: 'topicDetail', query: {id: articleId}})
    },
    loadMore () {
      if (this.hasNextPage) {
        this.getArticleList(this.pageNum)
      }
    },
    handleNewActive (item) {
      const index = this.articleList.findIndex((i) => {
        return i.articleId === item.articleId
      })
      this.articleList[index].articleVoteVO = item
    },
    favorite (res, index) {
      // 取消点赞
      if (res.isFavorites) {
        removeFavorites({
          entityId: res.articleId,
          entityType: '1'
        }).then(() => {
          let data = this.articleList[index]
          this.articleList[index].isFavorites = !res.isFavorites
          let count = data.articleStatisticVO.favoritesCount - 1
          if (count < 0) count = 0
          data.articleStatisticVO.favoritesCount = count
        })
      } else {
        // 添加点赞
        addFavorites({
          entityId: res.articleId,
          entityType: '1'
        }).then(() => {
          let data = this.articleList[index]
          this.articleList[index].isFavorites = !res.isFavorites
          // if (!data.articleStatisticVO) {
          //   data.articleStatisticVO = {}
          // }
          data.articleStatisticVO.favoritesCount =
            data.articleStatisticVO.favoritesCount + 1
        })
      }
    },
    like (res, index) {
      // 取消点赞
      if (res.isThumbsUp) {
        removeThumbsup({
          entityId: res.articleId,
          entityType: 1
          // eslint-disable-next-line no-unused-vars
        }).then(() => {
          let data = this.articleList[index]
          this.articleList[index].isThumbsUp = !res.isThumbsUp
          let count = data.articleStatisticVO.thumbsUpCount - 1
          if (count < 0) count = 0
          data.articleStatisticVO.thumbsUpCount = count
        })
      } else {
        // 添加点赞
        addThumbsup({
          entityId: res.articleId,
          entityType: 1
        }).then(() => {
          let data = this.articleList[index]
          this.articleList[index].isThumbsUp = !res.isThumbsUp
          // if (!data.articleStatisticVO) {
          //   data.articleStatisticVO = {}
          // }
          data.articleStatisticVO.thumbsUpCount =
            data.articleStatisticVO.thumbsUpCount + 1
        })
      }
    },
    handleComment (item) {
      this.showDialog = true
      this.commentParams = item
    },
    async handlePublishComment () {
      const {
        commentContent: content,
        commentParams: {articleId: entityId},
        isAnonymousComment: isAnonymous
      } = this
      const entityType = ENTITY_TYPE_ARTICLE
      if (!content.trim()) {
        this.$notify({message: '评论内容不能为空', type: 'danger'})
        return
      }

      await addComment({content, entityId, entityType, isAnonymous})
      this.showDialog = false
      this.commentParams = {}
      this.commentContent = ''
      this.articleList = this.articleList.map((item) => {
        if (item.articleId !== entityId) {
          return item
        }
        const {articleStatisticVO} = item
        return {
          ...item,
          articleStatisticVO: {
            ...articleStatisticVO,
            commentCount: articleStatisticVO.commentCount + 1
          }
        }
      })
    }
  },
  beforeDestroy () {
    // 下次进入页面，显示离开时的选项
    setItem('sortKey', this.sortKey)
  }
}
</script>

<style lang="scss" scoped>
.userInfoDetail {
  height: 100%;
  display: flex;
  flex-direction: column;

  &_head {
    border-bottom: solid 1px #cccccc;
  }

  &_iconSearch {
    width: 14px;
  }

  &_infoCard {
    display: flex;
    align-items: center;
    background-color: white;
    padding: 20px;
    justify-content: space-between;

    .tag {
      font-size: 12px;
      color: white;
      background-color: $mainColor;
      padding: 0 13px;
      height: 24px;
      line-height: 24px;
      border-radius: 12px;
    }
  }

  &_filter {
    font-size: 14px;
    display: flex;
    justify-content: space-between;
    padding: 10px 20px 10px;

    .articleNum {
      color: $mainTextColor;
    }
  }

  &_content {
    flex: 1;
    overflow-y: auto;

    .topicItem {
      margin: 0px 10px 10px 10px !important;
    }

    > :not(:last-child):after {
      content: '';
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
      margin: 0 20px;
      height: 1px;
      background-color: #cccccc;
    }
  }
}
</style>
