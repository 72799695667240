<template>
  <div class="userinfoCard">
    <AvatarFrame class="userinfoCard_icon"
      v-if="Object.keys(userInfo).length > 0" :user-info="userInfo"></AvatarFrame>
    <div class="userinfoCard_text">
      <p class="username">
        <span v-if="admin===0">{{ username }}</span>
        <span v-else-if="admin===1||admin===2">
          {{ ADMIN_NAME }}
        </span>
      </p>
      <p class="department">{{ department }}</p>
    </div>
  </div>
</template>

<script>
import AvatarFrame from '@/components/avatarFrame'
import {ADMIN_IMAGE, ADMIN_NAME} from "@/config/config";

export default {
  components: {AvatarFrame},
  props: {
    userInfo: {
      type: Object,
      default: () => {
      }
    },
    userIcon: String,
    username: String,
    department: String,
    admin: {
      type: Number,
      default: () => 0
    },
  },
  watch: {
    userInfo: {
      handler (value) {
        console.log(value)
      },
      deep: true
    }
  },
  computed: {
    avatar () {
      if (this.admin === 1 || this.admin === 2) {
        return ADMIN_IMAGE
      } else {
        return this.userIcon
      }
    }
  },
  data () {
    return {
      ADMIN_NAME
    }
  }
}
</script>

<style lang="scss" scoped>
.userinfoCard {
  display: flex;

  &_icon {
    width: 80px;
    height: 80px;
  }

  &_text {
    margin-left: 20px;
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    padding-top: 12px;

    .username {
      color: $titleColor;
      font-size: 18px;
      margin: 0;
      line-height: 40px;
    }

    .department {
      max-width: 160px;
      color: $mainTextColor;
      font-size: 14px;
      margin: 0;
    }
  }
}
</style>
